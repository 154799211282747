// extracted by mini-css-extract-plugin
export var date = "index-module--date--utgeS";
export var details2 = "index-module--details2--MhLIU";
export var intro = "index-module--intro--vXGrl";
export var introTitle = "index-module--introTitle--KGE3c";
export var itemDetail = "index-module--itemDetail--jpAWJ";
export var itemTitle = "index-module--itemTitle--enuHd";
export var list = "index-module--list--0QLKB";
export var listItem = "index-module--listItem--bQ8No";
export var serviceRulesPage = "index-module--serviceRulesPage--0RQMa";
export var smallList = "index-module--smallList--W-b2Q";