import React from "react";
import Helmet from "react-helmet";
import * as styles from "./index.module.scss";

import { Col, Collapse, Row } from "antd";

import Footer from "@components/footer";
import Header from "@components/header";
import TextCenterBanner from "@components/textCenterBanner";

import phoneImg from "@src/images/privacyPolicy/Rectangle4386.png";
import pcImg from "@src/images/privacyPolicy/Rectangle4387.png";

const renderDatas = [
  {
    title: "1.介绍",
    details: [
      "欢迎使用Shoprise！ ",
      "Shoprise（“我们”）重视用户的隐私。为了创建更好的运营服务，我们会收集和使用您的相关信息。我们希望通过本《隐私政策》（本政策）向您说明在使用我们的产品/或服务时如何收集、使用和保存、共享和转让这些信息，无论您是Shoprise服务用户、Shoprise服务用户的顾客、或者是浏览访问Shoprise网站访客。本《隐私政策》与您所使用的Shoprise服务息息相关，希望您仔细阅读，在需要时，按照本《隐私政策》的指引，作出您认为适当的选择。本《隐私政策》中涉及的相关技术词汇，我们尽量以简明扼要的表述，并提供进一步说明的注释，以便您的理解。",
      "您使用或与使用我们服务的商家进行交易时，即表示您同意了本《隐私政策》里所列的条款以及相关的Shoprise《服务条款》。 ",
      "我们会根据Shoprise运营、法律或合规需求不定时的修订本《隐私政策》的条款。如该修订对《隐私政策》产生实质改变，我们将在Shoprise网站公示此修订的政策或以其他适当的方式通知您。在修订公示以后，您对Shoprise网站和服务的继续使用即视为同意接受经修订的《隐私政策》。",
    ],
  },

  {
    title: "2.收集来自商家的信息",
    details: [
      "作为商家，您同意在店铺网站首页发布符合您所从事的商业活动的相关法律法规要求的隐私政策。鉴于Shoprise会获取并使用到您顾客的个人信息，您同时确认已获取您客户的许可允许Shoprise及其他相关第三方使用和获取他们个人信息。此外，如果您要从您的顾客处收集任何个人敏感信息（包括有关医疗或健康状况、种族或民族起源、政治观点、宗教或者哲学信仰、工会成员或性取向等信息），鉴于Shoprise会获取到并使用这些敏感的信息，您确认已获取他们肯定的及明确的批准。 ",
      "  • 我们会收集您的姓名、公司名称、地址、电子邮件地址、电话号码（手机或座机号）以及信用卡信息。 ",
      "我们需要这些信息为商家提供服务，包括支付和处理订单、验证和处理付款。同时，我们通过这些信息来改善我们的服务。 ",
      "  • 我们收集您访问托管在Shoprise上的网页的相关数据。我们还会收集有关您访问账号的方式和时间的相关数据，包括您使用的设备和浏览器，网络连接以及您的IP地址等信息。 ",
      "  - 我们需要这些信息来帮助您访问并改进我们的服务。 ",
      "  • 我们收集您与我们分享的客户信息，或者由正在购物或结账的客户提供的相关个人信息。 ",
      "  - 我们用这些信息为您提供我们的服务，以便您能够处理订单并更好地为您的客户提供服务。",
      "  • 在其他您明确许可的情况下，我们会收集个人信息。",
      " 何时收集这些信息？ ",
      "  • 在您注册我们的服务、获取使用我们的服务或者以其他方式向我们提供信息时，我们会收集您的个人信息。",
    ],
  },
  {
    title: "3.收集来自商家顾客的信息",
    details: [
      "会收集哪些信息？ ",
      "• 我们收集商家顾客的姓名、电子邮件地址、物流配送和账单地址、支付资料、公司名称、电话号码、IP地址和设备数据。",
      "- 我们需要这些信息为商家提供服务，包括支付和处理订单、验证和处理付款。同时，我们通过这些信息来改善我们的服务。",
      "何时收集这些信息？ ",
      "• 当商家的顾客使用或访问我们的服务时（例如，当客户访问商家网站、下订单或在商家网站上注册账户时）收集信息。",
    ],
  },

  {
    title: "4.收集来自一键迁移用户的信息",
    details: [
      "会收集哪些信息？",
      "  • 我们收集您要迁移店铺的信息，可能包括商品信息、博客信息或顾客信息，这将取决于您选择的要迁移的模块。",
      "- 因为您需要使用我们的一键迁移功能完成数据从您的其他店铺到Shoprise店铺的一键导入，为了实现此功能，我们需要获取、收集这些信息并转移到您的Shoprise店铺。",
      " 何时收集这些信息？",
      " • 当您开始使用我们的一键迁移功能时，我们收集这些信息。",
    ],
  },
  {
    title: "5.收集来自Shoprise网站浏览、咨询客户的信息",
    details: [
      "会收集哪些信息？ ",
      "• 对于Shoprise网站的访问者，我们收集您使用的设备和浏览器、网络和您的IP地址信息。我们在您使用Shoprise网站与Shoprise进行即时信息互动时收集您提供的信息。",
      "• 对于邮件或者电话的咨询客户，我们可能会收集您的姓名、邮箱、电话号码、使用设备、浏览器、网络、IP地址和通信记录等信息。",
      "- 我们使用这些信息来提供和增强我们的服务（包括对您的账户服务），并回答您可能有的问题。",
      "何时收集这些信息？ ",
      "• 我们在您访问Shoprise托管的网页、使用Shoprise网站提供的服务、或通过电子邮件、网站、即时信息、电话或通过Shoprise发布信息时收集信息。并且我们收集其他您可能提供给我们的信息。",
    ],
  },

  {
    title: "6.信息共享",
    details: [
      "  • 我们不会与Shoprise以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：",
      "  1.事先获得您明确的同意或授权； ",
      "  2.根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供； ",
      "  3.在法律法规允许的范围内，为维护Shoprise、Shoprise的关联方或合作伙伴、您或其他Shoprise用户或社会公众利益、财产或安全免遭损害而有必要提供；",
      " 4.只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；",
      "  5.应您需求为您处理您与他人的纠纷或争议；",
      "  6.符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；",
      "  7.基于学术研究而使用；",
    ],
  },

  {
    title: "7.基于符合法律法规的社会公共利益而使用",
    details: [
      "• 我们可能会将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。",
      "• 我们可能会向合作伙伴等第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。 我们的合作伙伴包括以下类型： ",
      "1.商品或技术服务的供应商。我们可能会将您的个人信息共享给支持我们功能的第三方。这些支持包括为我们的供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理等。我们共享这些信息的目的是可以实现我们产品与/或服务的核心购物功能，比如我们必须与物流服务提供商共享您的订单信息才能安排送货；或者我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付等。 ",
      "2.第三方商家。我们必须将您的订单信息与交易有关的必要信息与第三方商家共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。 ",
      "3.委托我们进行推广的合作伙伴。有时我们会代表其他企业向使用我们产品与/或服务的用户群提供促销推广的服务。我们可能会使用您的个人信息以及您的非个人信息集合形成的间接用户画像与委托我们进行推广的合作伙伴（“委托方”）共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。 ",
      "• 对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。 ",
      "• 为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护Shoprise、您或其他Shoprise客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过，这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。 ",
      "• 您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意： ",
      "1.与国家安全、国防安全有关的； ",
      "2.与公共安全、公共卫生、重大公共利益有关的； ",
      "3.与犯罪侦查、起诉、审判和判决执行等有关的； ",
      "4.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； ",
      "5.所收集的个人信息是个人信息主体自行向社会公众公开的； ",
      "6.从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道； ",
      "7.根据您的要求签订合同所必需的； ",
      "8.用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障； ",
      "9.为合法的新闻报道所必需的； ",
      "10.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的； ",
      "11.法律法规规定的其他情形。 ",
      "• 我们可能从第三方获取您授权共享的账户信息（头像、昵称）。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。",
    ],
  },
  {
    title: "8. 注销账户",
    details: [
      "• 您可以在我们的产品中直接申请注销账户。关于您注销账户的方式以及您应满足的条件，请详见《Shoprise账户注销须知》。 您注销户后，我们将停止为您提供产品与/或服务，并依据您的要求，除法律法规另有规定外，我们将删除您的个人信息。 ",
      "• 为了合法的商业目的并在遵守相关法律法规的情况下，我们将继续存储您相关交易记录。 ",
      "• 为了改善我们的服务，我们将继续存储匿名或被匿名的信息，例如无识别的网站访问。",
    ],
  },
  {
    title: "9. 保障您的个人信息",
    details: [
      "• 我们不会向其他公司（除本政策约定外）分享、披露、出售、出租或提供个人信息以营销他们自己的产品或服务。 ",
      "• 如果您是使用Shoprise服务的商家，我们不会使用我们从您或您的客户处收集的个人信息单独联系或向您的客户营销。但是如果我们是从其他渠道获取到您客户的信息，比如客户自己提供给我们，Shoprise可能会联系或向这些客户营销。",
    ],
  },
  {
    title: "10.信息安全",
    details: [
      "• 我们遵守行业信息安全管理标准，保护敏感信息，如财务信息、知识产权、员工信息和其他任何被委托的个人信息。我们的信息安全系统基于风险管理运用于人员、流程和信息技术系统。 ",
      "• 互联网上的传输方式或电子存储方法不是100%安全的。因此，我们不能保证您个人信息的绝对安全，但我们将尽力确保或担保您发送给我们的任何信息的安全性。 ",
      "• 互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。",
    ],
  },
  {
    title: "11.管理您的个人信息",
    details: [
      "• 您保留个人信息的所有权利，并且可以随时访问。此外，Shoprise会采取合理的措施，允许您更正、修改、删除或限制您个人信息的使用。您可以在账户设置中直接更新许多类型的个人信息，例如，支付和联系信息。如果您无法在您的账户设置中更改您的个人信息，请联系我们进行必要的更改。请务必记住，如果您删除或限制我们使用您的个人信息，有一些服务可能会无法正常运行。 ",
      "• 请您注意，当您成功申请注销Shoprise账户后，我们将在您提出申请的48小时内完成对您的账户注销审核，审核通过后，我们将对您的个人信息进行删除或匿名化处理。 ",
      "• 如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。",
    ],
  },
  {
    title: "12.修订及通知",
    details: [
      "• 为给您提供更好的服务以及随着Shoprise业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在Shoprise网站、Shoprise移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问Shoprise以便及时了解最新的隐私政策。 ",
      "• 对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。",
    ],
  },
  {
    title: "本政策所指的重大变更包括但不限于：",
    details: [
      "1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；",
      "2.我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；",
    ],
    date: ["最近更新日期：2021年05月31日", "生效日期：2021年05月31日"],
  },
];

const PrivacyPolicyPage = () => {
  return (
    <div className={styles.serviceRulesPage}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>隐私政策-Shoprise 官网</title>
      </Helmet>
      <Header />
      <TextCenterBanner pcImg={pcImg} phoneImg={phoneImg} text="隐私政策" />
      <Row>
        {/* pc 端 */}
        <Col xs={0} sm={0} md={24}>
          <div className={styles.list}>
            {renderDatas.map((item) => {
              return (
                <div className={styles.listItem} key={item.title}>
                  <div className={styles.itemTitle}>{item.title}</div>
                  {item.details.map((text, index) => (
                    <div className={styles.itemDetail} key={index}>
                      {text}
                    </div>
                  ))}
                  {item.intro && (
                    <div className={styles.intro}>
                      <div className={styles.introTitle}> 说明 </div>
                      {item.intro.map((intro) => {
                        return <div key={intro}>{intro}</div>;
                      })}
                    </div>
                  )}
                  {item.anotherDetails && (
                    <div className={styles.details2}>
                      {item.anotherDetails.map((text) => (
                        <div className={styles.itemDetail} key={text}>
                          {text}
                        </div>
                      ))}
                    </div>
                  )}

                  {item.date && (
                    <div className={styles.date}>
                      {item.date.map((date) => (
                        <div key={date}>{date}</div>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Col>
        {/* 移动端 */}
        <Col xs={24} sm={24} md={0}>
          <div className={styles.smallList}>
            <Collapse
              defaultActiveKey={[0]}
              expandIconPosition="right"
              ghost
              bordered="false"
            >
              {renderDatas.map((item) => (
                <Collapse.Panel header={item.title} key={item.title}>
                  {/* <div className={styles.itemTitle}>{item.title}</div> */}
                  {item.details.map((text, index) => (
                    <div className={styles.itemDetail} key={index}>
                      {text}
                    </div>
                  ))}
                  {item.intro && (
                    <div className={styles.intro}>
                      <div className={styles.introTitle}> 说明 </div>
                      {item.intro.map((intro) => {
                        return <div key={intro}>{intro}</div>;
                      })}
                    </div>
                  )}
                  {item.anotherDetails && (
                    <div className={styles.details2}>
                      {item.anotherDetails.map((text) => (
                        <div className={styles.itemDetail} key={text}>
                          {text}
                        </div>
                      ))}
                    </div>
                  )}

                  {item.date && (
                    <div className={styles.date}>
                      {item.date.map((date) => (
                        <div key={date}>{date}</div>
                      ))}
                    </div>
                  )}
                </Collapse.Panel>
              ))}
            </Collapse>
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
